<template>
  <div class="grey lighten-1" style="height: 100%">
    <div class="pa-3 d-flex align-center justify-space-between">
      <div><img src="@/assets/text_econsenso.png" width="200" /></div>
      <div class="text-h6" align="end">
        <div>Já possui cadastro?</div>
        <v-btn exact color="white" :to="{ name: 'login' }"> Login </v-btn>
      </div>
    </div>
    <div
      align="center"
      class="grey lighten-1 d-flex justify-center align-center"
    >
      <div align="center" class="pb-5">
        <v-card max-width="500" class="transparent elevation-0">
          <div class="text-h3 font-weight-bold">Cadastro</div>
          <div class=" title mb-3">
            Pronto para fazer seu cadastro?
            <br/>
            É simples e rápido
          </div>
          <v-form ref="registerForm" @submit.prevent="sendRegister()">
            <v-text-field
              v-model="register.name"
              label="Nome*"
              filled
              color="grey darken-2"
              hide-details="auto"
              class="mx-5 white white--text"
              :rules="[$rules.required]"
              :disabled="loadingSave"
            />
            <v-text-field
              v-model="register.email"
              label="Email *"
              filled
              hide-details="auto"
              color="grey darken-2"
              class="ma-5 white"
              :rules="[$rules.required, $rules.email]"
              :disabled="loadingSave"
            />
            <v-text-field
              v-model="register.phone"
              label="Telefone*"
              filled
              hide-details="auto"
              color="grey darken-2"
              class="ma-5 white"
              v-mask="['(##) ####-####', '(##) #####-####']"
              placeholder="(00) 00000-0000"
              :rules="[$rules.required, $rules.phone]"
              :disabled="loadingSave"
            />
            <v-text-field
              
              label="Nome da Empresa*"
              filled
              hide-details="auto"
              color="grey darken-2"
              class="ma-5 white"
              :rules="[$rules.required]"
              :disabled="loadingSave"
            />
            <v-text-field
              
              label="Posição na Empresa*"
              filled
              hide-details="auto"
              color="grey darken-2"
              class="ma-5 white"
              :rules="[$rules.required]"
              :disabled="loadingSave"
            />
            
            <div class="d-flex align-center justify-space-between">
              <v-checkbox
                :disabled="loadingSave"
                v-model="checkbox"
                label="Aceito os termos e condições de uso e Políticas de Privacidade *"
                class="ml-4 ma-0 pa-0"
                hide-details="auto"
                :rules="[(val) => !!val || 'Você deve aceitar para continuar']"
              />

              <v-btn :loading="loadingSave" color="green" dark type="submit">
                Criar Conta
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",

  data: () => ({
    loading: false,
    loadingSave: false,
    confirmPassword: false,
    confirmRepeat: false,
    showPassword: false,
    value: false,
    checkbox: false,
    register: {
      name: "",
      phone: "",
      email: "",
      comapny_name: "",
      company_position: "",
    },
  }),
  methods: {
    sendRegister() {
      if (!this.$refs.registerForm.validate()) {
        this.$showMessage("error", "Preencha todos os campos corretamente");
      } else {
        this.loadingSave = true;
        this.$axios
          .post("/user", this.register)
          .then((reponse) => {
            this.$showMessage("success", "Usuário Cadastrado com sucesso");
            this.$router.replace({
              name: "app",
            });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.$showMessage(
                  "warning",
                  "Usuário com esse email já cadastrado"
                );
                return;
              }
              if (error.response.status == 409) {
                this.$showMessage("warning", "CNPJ já cadastrado");
                return;
              }
              if (error.response.status == 401) {
                this.$showMessage(
                  "warning",
                  "Os números do CNPJ são inválidos"
                );
                return;
              }
            }
            this.$showMessage("error", "Falha Inesperada no Cadastro");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
  },
};
</script>
